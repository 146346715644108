import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Section } from 'common/components'
import { SingleSelectField } from 'common/components'
import {
  DATE_FORMATS,
  getLanguages,
  MEASUREMENT_SYSTEM_FORMATS,
  NUMBER_FORMATS,
} from 'user/utils'

interface IProps {
  isLoading: boolean
}

export default function EditUserSettingsFormFields({ isLoading }: IProps) {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Section settingSection title={t('user.section.settings')}>
      <div className={'flex flex-col items-center w-full gap-1'}>
        <SingleSelectField
          control={control}
          data={getLanguages()}
          isDisabled={isLoading}
          label={t('user.field.language')}
          name={'language'}
        />
        <SingleSelectField
          control={control}
          data={DATE_FORMATS}
          isDisabled={isLoading}
          label={t('user.field.dateFormat')}
          name={'dateFormat'}
        />
        <SingleSelectField
          control={control}
          data={NUMBER_FORMATS}
          isDisabled={isLoading}
          label={t('user.field.numberFormat')}
          name={'numberFormat'}
        />
        <SingleSelectField
          control={control}
          data={MEASUREMENT_SYSTEM_FORMATS}
          isDisabled={isLoading}
          label={t('user.field.measuremenetSystem')}
          name={'measurementSystem'}
        />
      </div>
    </Section>
  )
}
