import { forwardRef, Ref, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '@mantine/core'
import clsx from 'clsx'
import { isNil } from 'lodash'

import classes from './logo.module.css'

interface IProps {
  logoUrl: string
  width?: number
  height?: number
  alt?: string
  link?: string
  text?: string
  classes?: {
    image?: string
    link?: string
  }
  onClick?: () => void
}

function ClientLogo(
  {
    logoUrl,
    width,
    height = 20,
    alt,
    link,
    text,
    classes: classesFromProps,
    onClick,
  }: IProps,
  ref: Ref<HTMLAnchorElement>,
) {
  const renderLogo = useMemo(() => {
    const image = (
      <img
        alt={alt}
        className={clsx(classesFromProps?.image, isNil(text) ? '' : 'ml-1')}
        height={height}
        src={logoUrl}
        width={width}
      />
    )

    if (text) {
      return (
        <>
          {image}
          <Text className={classes.logoText}>{text}</Text>
        </>
      )
    } else {
      return image
    }
  }, [alt, classesFromProps?.image, height, text, logoUrl, width])

  if (link) {
    return (
      <Link
        className={clsx(
          'inline-block',
          classesFromProps?.link,
          isNil(text) ? '' : 'flex items-center gap-2 no-underline',
        )}
        onClick={onClick}
        ref={ref}
        to={link}
      >
        {renderLogo}
      </Link>
    )
  }

  return renderLogo
}

export default forwardRef(ClientLogo)
