import { forwardRef, Ref, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ActionIcon, Indicator, Menu as MantineMenu, Text } from '@mantine/core'
import { IconMenu2 } from '@tabler/icons-react'
import clsx from 'clsx'
import { IMenuItem } from 'common/models'
import { hasProperty } from 'common/utils'

import classes from './header.module.css'

interface IProps {
  visibleMenu: IMenuItem[]
  hiddenMenu: IMenuItem[]
  isDropdownMenuVisible: boolean
  setIsDropdownMenuVisible: (value: boolean) => void
}

function Menu(
  { visibleMenu, hiddenMenu, isDropdownMenuVisible, setIsDropdownMenuVisible }: IProps,
  ref: Ref<HTMLDivElement>,
) {
  const location = useLocation()

  const [, setChildMenuStates] = useState<Record<string, boolean>>({})
  return (
    <div className={'flex items-center justify-between flex-1 gap-2'} ref={ref}>
      <nav className={'flex items-center gap-6 ml-10'}>
        {visibleMenu.map((item) => {
          return hasProperty(item, 'childs') ? (
            <MantineMenu
              classNames={{ dropdown: 'flex flex-col gap-1 !w-[250px] p-2' }}
              closeDelay={400}
              key={item.link}
              offset={10}
              openDelay={100}
              position={'bottom-start'}
              trigger={'hover'}
              withinPortal={false}
            >
              <MantineMenu.Target>
                <button
                  className={clsx(
                    'no-underline font-normal text-[16px] text-white bg-transparent p-0 border-0 hover:cursor-pointer',
                    location.pathname.includes(item.link) &&
                      '!underline underline-offset-8 decoration-2',
                  )}
                >
                  {item.label}
                </button>
              </MantineMenu.Target>
              <MantineMenu.Dropdown>
                {(item.childs ?? []).map((i) => (
                  <Link
                    className={clsx(
                      'no-underline font-medium text-black hover:bg-gray-200 p-2 rounded-sm',
                      location.pathname.replace('/', '') === i.link &&
                        '!underline underline-offset-8 decoration-2',
                    )}
                    key={`${item.link}/${i.link}`}
                    onClick={() =>
                      setChildMenuStates((prev) => ({ ...prev, [item.link]: false }))
                    }
                    to={`${item.link}/${i.link}`}
                  >
                    {i.label}
                  </Link>
                ))}
              </MantineMenu.Dropdown>
            </MantineMenu>
          ) : (
            <Link
              className={clsx(
                'no-underline font-normal text-[16px] text-white',
                location.pathname === item.link &&
                  '!underline underline-offset-8 decoration-2',
              )}
              key={item.link}
              to={item.link}
            >
              {item.label}
            </Link>
          )
        })}
      </nav>
      <MantineMenu
        classNames={{ dropdown: 'flex flex-col gap-1 !w-[250px] p-4' }}
        onChange={setIsDropdownMenuVisible}
        opened={isDropdownMenuVisible}
        withinPortal={false}
      >
        <MantineMenu.Target>
          <Indicator
            className={hiddenMenu.length > 0 ? 'block' : 'hidden'}
            classNames={{ indicator: classes.indicatorNumber }}
            inline
            label={hiddenMenu.length}
            size={14}
          >
            <ActionIcon
              className={clsx(
                'active:transform-none text-white',
                hiddenMenu.length > 0 ? 'block' : 'hidden',
              )}
              // onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              variant={'transparent'}
            >
              <IconMenu2 stroke={2} />
            </ActionIcon>
          </Indicator>
        </MantineMenu.Target>
        <MantineMenu.Dropdown>
          {hiddenMenu.map((item) => {
            if (hasProperty(item, 'childs')) {
              return (
                <div key={item.label}>
                  <Text
                    className={'no-underline uppercase text-sm font-medium text-zinc-600'}
                  >
                    {item.label}
                  </Text>
                  <div className={'flex flex-col'}>
                    {item.childs?.map((sub) => (
                      <Link
                        className={'no-underline font-medium text-black'}
                        key={sub.label}
                        to={`${item.link}/${sub.link}`}
                      >
                        {sub.label}
                      </Link>
                    ))}
                  </div>
                </div>
              )
            }

            return (
              <Link
                className={'no-underline font-medium text-black'}
                key={item.link}
                onClick={() => {
                  if (isDropdownMenuVisible) {
                    setIsDropdownMenuVisible(false)
                  }
                }}
                to={item.link}
              >
                {item.label}
              </Link>
            )
          })}
        </MantineMenu.Dropdown>
      </MantineMenu>
    </div>
  )
}

export default forwardRef(Menu)
