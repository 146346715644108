import { first } from 'lodash'
import {
  IEditUserNotificationsFormValues,
  IEditUserProfileFormValues,
  IEditUserSettingsFormValues,
} from 'user/models'

import {
  DATE_FORMATS,
  getLanguages,
  MEASUREMENT_SYSTEM_FORMATS,
  NUMBER_FORMATS,
} from './user.utils'

export const USER_PROFILE_FIELDS_DEFAULT_VALUES: IEditUserProfileFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  phoneNumber: '',
  phoneNumber2: '',
}

export const USER_SETTINGS_FIELDS_DEFAULT_VALUES: IEditUserSettingsFormValues = {
  language: first(getLanguages())?.value || '',
  dateFormat: first(DATE_FORMATS)?.value || '',
  numberFormat: first(NUMBER_FORMATS)?.value || '',
  measurementSystem: first(MEASUREMENT_SYSTEM_FORMATS)?.value || '',
}

export const USER_NOTIFICATIONS_FIELDS_DEFAULT_VALUES: IEditUserNotificationsFormValues =
  {
    sendEmail: false,
    sendNotification: false,
  }
